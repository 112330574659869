import { Component,Input,Output,EventEmitter, SimpleChanges, OnChanges } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Component({
        selector:'custom-file-upload',
        templateUrl:'./file-upload.component.html',
        styleUrls:['./file-upload.component.scss']
    })
    export class CustomFileUploadComponent implements OnChanges{
  
        @Input()
        public accept:string;

        @Input() 
        public disabled:boolean=false;

        @Input() 
        public origin: "logo-Input" | "banner-Input";

        @Input() 
        public fileUpdated:boolean=false;

        @Output() 
        public inputFileChange= new EventEmitter<FileList>();

        public fileName$: BehaviorSubject<string> = new BehaviorSubject("");

        public isFileNameAvailable:boolean=false;  
       
        ngOnChanges(changes: SimpleChanges): void {
            if(changes.fileUpdated && changes.fileUpdated.currentValue)
                this.isFileNameAvailable=false;
        }

        fileInputChanged(event)
        {
            if(event.target.files[0]!=null || event.target.files[0]!=undefined){
            this.fileName$.next(event.target.files[0].name);
            this.isFileNameAvailable=true;
            this.inputFileChange.emit(event.target.files);
            }
        }
    }
