import { Injectable } from '@angular/core';
import { Router, ResolveEnd, ActivatedRouteSnapshot } from '@angular/router';

import { ApplicationInsights, IEventTelemetry, IMetricTelemetry } from '@microsoft/applicationinsights-web';

import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AdminAppEnvironment as environment } from 'visenvironment';

import { OAuthService, OAuthEvent } from 'angular-oauth2-oidc';

import { ZeissIdToken, ZeissIdBase } from 'visauto-auth';

@Injectable({ providedIn: 'root' })
export class ApplicationInsightsService {

    private routerSubscription: Subscription;

    private oAuthSubscription: Subscription;

    private appInsights = new ApplicationInsights({
        config: { connectionString: environment.monitoring.appInsightsConnectionString, enableAutoRouteTracking: false }
    });

    constructor(private router: Router, private oAuth: OAuthService) {
        this.appInsights.loadAppInsights();

        // Subscribe to auth changes
        this.oAuthSubscription = this.oAuth.events.pipe(filter(event => event.type === 'token_received')).subscribe((event: OAuthEvent) => {
            const user = this.oAuth.getIdentityClaims() as ZeissIdToken;
            if (user) {
                const base = JSON.parse(user.ZeissIdBase) as ZeissIdBase;
                this.setUserId(base.accountId.toString());
            }
        });
    }

    public logMetric(name: string, metric: number) {
        this.appInsights.trackMetric({ name, average: metric });
    }

    public logPageView(name?: string, uri?: string) {
        if (!uri.includes('#id_token')) {
            this.appInsights.trackPageView({ name, uri });
        }
    }

    public logAction(name: string, properties: any) {
        this.appInsights.trackEvent({ name, properties });
    }

    public logException(error: Error, data: any) {
        this.appInsights.trackException({ exception: error, properties: { data } });
    }

    public setUserId(userId: string) {
        this.appInsights.setAuthenticatedUserContext(userId);
    }

    public clearUserId() {
        this.appInsights.clearAuthenticatedUserContext();
    }

    private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
        if (snapshot.firstChild) {
            return this.getActivatedComponent(snapshot.firstChild);
        }

        return snapshot.component;
    }

    private getRouteTemplate(snapshot: ActivatedRouteSnapshot) {
        let path = '';
        if (snapshot.routeConfig) {
            path += snapshot.routeConfig.path;
        }

        if (snapshot.firstChild) {
            return path + this.getRouteTemplate(snapshot.firstChild);
        }

        return path;
    }
}

export class LogEvent implements IEventTelemetry {

    name: string;
    properties?: { [key: string]: any; };
    measurements?: { [key: string]: number; };


}

export enum LogEventTypes {
    delete_avatar_session,
    publish_session_survey,
    publish_to_social_media,
    add_feedback_for_survey
}

export enum LogEventSender {
    User,
    System
}

export enum LogEventClassification {
    Info,
    Warning,
    Error
}
