import { InitialData } from './../../../types/web-worker.model';
import { Component, OnInit, OnDestroy,Renderer2,ElementRef } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { authConfig as AUTHCONFIG, discoverDocumentOverride as DISCOVERY_URL_OVERRIDE } from '../../configs/auth.config';
import { Router } from '@angular/router';
import { SecurityService } from '../../services/security.service';
import { MatDialog } from '@angular/material/dialog';
import { LanguageSelectionDialogComponent } from '../../dialogs/language-selection/language-selection.dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { AdminAppEnvironment as environment } from 'visenvironment';
import { FAQDialogComponent } from '../../dialogs/FAQ/faq.page.component';
import { LanguageService } from '../../services/language.service';
import { HostListener } from '@angular/core';


type Language = {
    name: string;
    code: string;
};

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
    public get availableLanguages() {
        return this.templateLanguages;
    }

    public adminMenuExpand: boolean;

    private keyFunctionCodeArray: number[] = [37, 38, 39, 40];

    @HostListener("wheel", ["$event"])
    onMouseWheel(event: WheelEvent) {
        this.removeElement();
    }

    @HostListener("touchstart")
    onTouchStart(): void {
        this.removeElement();
    }
    @HostListener("document:keydown",["$event"])
    onKeyPress(event): void {
        if (this.keyFunctionCodeArray.includes(event?.keyCode)) {
            this.removeElement();
        }
    }
    //
    // only used for internal development
    //
    // 1. Skip the automatic login to be able to see the landing page
    private readonly isDebugMode = false;
    public templateLanguages: Language[];
    private worker!: Worker;
    private LAST_ACTION_STORE_KEY: string = 'lastAction';

    // bind listner to keypress and click to track user's activity.
    @HostListener('document:keypress')
    @HostListener('document:click')
    public resetIdleTimer(): void {
        if (this.worker) {
            localStorage.setItem(this.LAST_ACTION_STORE_KEY, Date.now().toString());
            this.worker.postMessage({ secondsIdle: 0 });
        }
    }

    constructor(
        private oAuth: OAuthService,
        private router: Router,
        private security: SecurityService,
        private dialog: MatDialog,
        private translate: TranslateService,
        private language: LanguageService,
        private renderer: Renderer2,
        private element: ElementRef,
        private langauage: LanguageService
    ) {

        this.language.availableLanguages$.subscribe((data)=>
        {
            this.templateLanguages = data;
        });
        
        this.translate.addLangs(
            this.availableLanguages.map((lang) => lang.code)
        );
        this.translate.setDefaultLang("en-US");

        // check for direct match
        let directMatch = this.availableLanguages.find(
            (l) => l.code === navigator.language
        )?.code;
        let inderectMatch = this.availableLanguages.find(
            (l) => l.code.split("-")[0] == navigator.language
        )?.code;
        let browserLang = directMatch ? directMatch : inderectMatch;

        let lang = "en-US";

        if (localStorage.getItem("vcldlang")) {
            lang = localStorage.getItem("vcldlang");
        } else {
            lang = browserLang ?? "en-US";
        }

        this.translate.use(lang);
    }

    removeElement() {
        const elements = document.getElementsByClassName("mdc-tooltip");
        if (elements?.length > 0) {
            this.renderer.removeChild(this.element.nativeElement, elements[0]);
        }
    }

    public get zeissLogo() {
        return environment.connectivity.zeiss_logo;
    }

    public get versionNumber() {
        return environment.version.split("+").shift();
    }

    async ngOnInit() {
        if (window.location.pathname !== "/logout") {
            this.initAuthentication();
        }

        await this.language.fetchLanguages();

        // setup webworker listner for logout action.
        this.setupWebWorker();

        // initialize webworker with configurations.
        this.pushInitialWorkerData();

    }

    // pass initial configuration data to web-worker.
    private pushInitialWorkerData() {
        let dataObj = {} as InitialData;
        const storageItem = localStorage.getItem(this.LAST_ACTION_STORE_KEY);

        if (storageItem) {
            dataObj.lastAction = parseInt(storageItem);
        } else {
            if (this.isAuthenticated) localStorage.setItem(this.LAST_ACTION_STORE_KEY, Date.now().toString());
            dataObj.lastAction = Date.now();
        }

        // get max idle time from environment file.
        dataObj.minutsUntilLogout = environment.authentication.autoLogoutInMinutes || 30;
        this.worker.postMessage({ 'initialData': dataObj });
    }

    private setupWebWorker() {
        if (typeof Worker !== 'undefined') {
            this.worker = new Worker(new URL('../../../web-workers/auto-logout.worker.ts', import.meta.url));
            this.worker.onmessage = ({ data }): void => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              if (data.logout && this.oAuth.hasValidIdToken()) {
                this.oAuth.logOut();
                localStorage.removeItem(this.LAST_ACTION_STORE_KEY);
              } else if (!this.oAuth.hasValidIdToken()) {
                if (localStorage.getItem(this.LAST_ACTION_STORE_KEY)) {
                    localStorage.removeItem(this.LAST_ACTION_STORE_KEY)
                }
              }
            };
          }
    }

    ngOnDestroy(): void {
        this.security.disposePermissionsStore();
    }

    private initAuthentication(): void {
        this.oAuth.configure(AUTHCONFIG);
        this.oAuth.setupAutomaticSilentRefresh({}, "id_token");
        this.oAuth
            .loadDiscoveryDocument(DISCOVERY_URL_OVERRIDE)
            .then(() => this.oAuth.tryLoginCodeFlow())
            .then(async () => {
                if (this.oAuth.hasValidIdToken()) {
                    // If succesfully logged in
                    const path = window.location.pathname;
                    if (path === "/after" || path === "//after") {
                        const role = await this.security
                            .resolvePermissionsByRole()
                            .toPromise();

                        if (role.id == "vis-role-co") {
                            this.router.navigate(["centration"]);
                        } else {
                            this.router.navigate(["home"]);
                        }
                    }
                    return;
                }
                this.oAuth.initCodeFlow();
            });
    }

    public logout(): void {
        this.security.disposePermissionsStore();
        this.oAuth.logOut();
    }

    public FuL() {
        window.open("http://www.framesulike.zeiss.com/", "_blank");
    }

    public isAuthenticated(): boolean {
        return (
            this.oAuth.getIdToken() != null ||
            this.oAuth.getIdToken() != undefined
        );
    }

    public get url() {
        return window.location.href;
    }

    public switchLanguage() {
        this.dialog
            .open(LanguageSelectionDialogComponent, {
                width: "fit-content",
                maxWidth: "99vw",
                autoFocus: false,
            })
            .afterClosed()
            .toPromise()
            .then((result) => {});
    }

    public openAbout() {
        this.dialog.open<FAQDialogComponent>(FAQDialogComponent, {
            width: "99vw",
            autoFocus: false,
        });
    }

    private login(): void {
        this.oAuth.initLoginFlow();
    }

    public getExpandMode(): string {
        if (window.innerWidth <= 1024) {
            return this.adminMenuExpand ? "over" : "side";
        } else {
            return "side";
        }
    }
}