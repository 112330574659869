export interface FavoriteFrame {
    frameId: string,
    coatingId?: string,
    tintId?: string,
    lastUpdate?:Date
}

export enum AvatarSource {
    STORE = "Store",
    VISUCLOUD = "VISUCLOUD",
    CAMERON = "Cameron",
    AP13 = "AP13"
}

export interface AvatarCreationSession {
    // Internal Id used by Cosmos DB
    id: string;

    opticianId: string;

    opticianCustomerNumber: string;

    storeId: string;

    consumerId?: string;

    uploadedAt?: Date;

    createdAt?: Date;

    lastAccess?: Date;

    source?: AvatarSource;

    feedbackCount?: number;

    avatarSchemaVersion?: string;

    shareToken?: string;

    favoritedFrames?: FavoriteFrame[];

    recommendedFrames?: string[];

    metadata?: {
        device: {
            deviceSerialNumber: string;
            cameraHeadSerialNumber: string;
            embeddedPcSerialNumber: string;
            pcbVersion: string;
            connectivityInterfaceVersion: string;
        };
    };

    binaries?: {
        calibration?: string;
        centralPicture?: string;
        headAnnotations?: string;
        mesh?: string;
        odFrontalPicture?: string;
        texture?: string;
    };

    opticianName: string;
}
