<div class="col s12">
    <mat-form-field style="height: auto;" class="col s7 m7 l4" appearance="outline">
        <mat-label>{{mailAddress}}</mat-label>
        <input [disabled]="disable" #emailInput vcldAutomationId="adm_app_vcld-adm-email-input_ecp-mail_input-field" matInput type="email" placeholder="{{'pages.store-settings.mail_placeholder' | translate}}"
          (input)="inputValueChanged($event)" (change)="displayError($event)" />
    </mat-form-field>

    <button  mat-flat-button color="primary" vcldAutomationId="adm_app_vcld-adm-email-input_save-mail_btn" style="margin-left: 24px; margin-top: 2px;" class="col s2 m2 l2" (click)="saveEmail()"
        [disabled]="isDisabled || disable">{{'components.buttons.save' | translate}}</button>

    <button *ngIf="showRemove" mat-icon-button color="primary" vcldAutomationId="adm_app_vcld-adm-email-input_delete_btn" style="margin-left: 24px;" matTooltip="{{'components.buttons.delete' | translate}}"
        (click)="removeEmail(i)" [disabled]="disable">
        <mat-icon>delete</mat-icon>
    </button>
</div>
<div class="col s12" *ngIf="errorMessage">
    <p class="error-text">{{errorMessage | translate }}</p>
</div>