import { AfterContentInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PreferencesService } from '../../services/preferences.service';
import { SettingsFlagType } from '../../models/store-setting.submitflag.model';

export type EmailSaveEvent = {
  email: string;
  index: number;
}

@Component({
  selector: 'vcld-adm-email-input',
  templateUrl: './email-input.component.html',
  styleUrls: ['./email-input.component.scss']
})
export class EmailInputComponent implements AfterContentInit {

  @Input()
  public mailAddress: string;

  @Input('index')
  public i: number;

  @Input()
  public showRemove: boolean;

  @Input()
  public disable: boolean = false;

  @Output()
  public onRemove: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  public onSave: EventEmitter<EmailSaveEvent> = new EventEmitter<EmailSaveEvent>();

  @Output()
  public onChange: EventEmitter<EmailSaveEvent> = new EventEmitter<EmailSaveEvent>();

  public errorMessage: string;

  @ViewChild('emailInput')
  private inputField: ElementRef;

  private disabled: boolean = true;

  private readonly EMAIL_REGEX: RegExp = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i;

  public get isDisabled(): boolean {
    return this.disabled;
  }
  
  constructor(private translate: TranslateService ,private pref:PreferencesService) { }

  public ngAfterContentInit() { 
    if(this.mailAddress === (this.translate.instant('pages.store-settings.mail_placeholder'))){
      this.showRemove = true;
    }
  }

  public removeEmail(index: number) {
    this.onRemove.emit(index);
  }

  public inputValueChanged(e: Event): void {
    const v = (e.target as HTMLInputElement).value;
    if (this.EMAIL_REGEX.test(v)) {
      this.errorMessage = null;
      this.disabled = false;
      const email: EmailSaveEvent = {
        email: this.inputField.nativeElement.value,
        index: this.i
      };
      this.onChange.emit(email);
    } else {
      this.disabled = true;
    }
    this.pref.setStoreSettingPageModificationFlag(SettingsFlagType.ModifiedFlag,!this.disabled);
  }

  public displayError(e: Event): void {
    if (this.disabled) {
      this.errorMessage = 'pages.store-settings.mail_placeholder';
    }
  }
  

  public saveEmail() {
    const e: EmailSaveEvent = {
      email: this.inputField.nativeElement.value,
      index: this.i
    };

    this.onSave.emit(e);
  }
}
