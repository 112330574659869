<mat-menu #menu="matMenu" yPosition="below" xPosition="after">
    <button mat-menu-item style="padding-left: 24px;" (click)="FuL()" vcldAutomationId="adm_app_app-root_frames-u-like_btn">{{'components.buttons.framesulike' |
        translate}}</button>
    <button mat-menu-item style="padding-left: 24px;" (click)="logout()" vcldAutomationId="adm_app_app-root_logout_btn">{{'components.buttons.logout' |
        translate}}</button>
</mat-menu>

<mat-drawer-container class="main-menu" autosize>
    <mat-drawer *ngIf="isAuthenticated()" #adminMenu [mode]="getExpandMode()" opened disableClose="true">
        <mat-list class="prevent-hover">
            <mat-list-item>
                <!-- TODO: Remove routerLink after development! -->
                <img matListItemIcon [src]="zeissLogo" width="24px" alt="logo"
                    style="width: 24px; object-fit: contain; border-radius: 0px;" routerLink="home" />
                <h3 matListItemTitle *ngIf="adminMenuExpand">{{'application.admin_webapp' | translate}}</h3>
            </mat-list-item>
        </mat-list>

        <mat-action-list class="spacer" *ngIf="isAuthenticated()">
            <div>
                <mat-list-item style="display: none;"
                    [ngClass]="{'selected' : url.includes('/avatar-overview') || url.includes('/accessed-avatars') || url.includes('/unaccessed-avatars')}"
                    [routerLink]="[ '/avatar-overview']" pk="admin_view_avatarsessionlist">
                    <mat-icon matListItemIcon vcldautomationid="adm_app_app-root_perm-identity_icon">perm_identity</mat-icon>
                    <h3 *ngIf="adminMenuExpand" matListItemTitle>{{'application.avatar_sessions' | translate}}</h3>
                </mat-list-item>

                <div featureFlag="DMTEnabled">
                    <mat-list-item style="display: none;" [ngClass]="{'selected' : url.includes('/marketing')}"
                        [routerLink]="[ '/marketing']" pk="admin_view_marketing">
                        <mat-icon matListItemIcon vcldautomationid="adm_app_app-root_mail-outline_icon">mail_outline</mat-icon>
                        <h3 *ngIf="adminMenuExpand" matListItemTitle>{{'application.dmt_title' | translate}}</h3>
                    </mat-list-item>
                </div>

                <mat-list-item style="display: none;" [ngClass]="{'selected' : url.includes('/store-settings')}"
                    [routerLink]="[ '/store-settings']" pk="admin_view_ecpsettings">
                    <mat-icon matListItemIcon vcldautomationid="adm_app_app-root_settings_icon">settings</mat-icon>
                    <h3 *ngIf="adminMenuExpand" matListItemTitle>{{'application.store_settings' | translate}}</h3>
                </mat-list-item>

                <mat-list-item style="display: none;" [ngClass]="{'selected' : url.includes('/centration')}"
                    [routerLink]="[ '/centration']" pk="admin_view_centration">
                    <mat-icon matListItemIcon vcldautomationid="adm_app_app-root_filter-center-focus_icon">filter_center_focus</mat-icon>
                    <h3 *ngIf="adminMenuExpand" matListItemTitle>{{'application.virtual_centration' | translate}}</h3>
                </mat-list-item>

                <mat-list-item style="display: none;" [ngClass]="{'selected' : url.includes('/app-settings')}"
                    [routerLink]="[ '/app-settings']" pk="admin_full_roleassignments">
                    <mat-icon matListItemIcon vcldautomationid="adm_app_app-root_admin-panel-settings_icon">admin_panel_settings</mat-icon>
                    <h3 *ngIf="adminMenuExpand" matListItemTitle>{{'application.application_settings' | translate}}</h3>
                </mat-list-item>

                <mat-list-item style="display: none;" [ngClass]="{'selected' : url.includes('/onboarding-wizard')}"
                    [routerLink]="[ '/onboarding-wizard']" pk="admin_view_onboarding">
                    <mat-icon matListItemIcon vcldautomationid="adm_app_app-root_local-airport_icon">local_airport</mat-icon>
                    <h3 *ngIf="adminMenuExpand" matListItemTitle>{{'application.onboarding_wizard' | translate}}</h3>
                </mat-list-item>
            </div>
            <div>
                <mat-list-item (click)="openAbout()">
                    <mat-icon matListItemIcon vcldautomationid="adm_app_app-root_info_icon">info</mat-icon>
                    <h3 matListItemTitle *ngIf="adminMenuExpand">{{'application.about' | translate}}</h3>
                </mat-list-item>
                <mat-list-item (click)="switchLanguage()">
                    <mat-icon matListItemIcon vcldautomationid="adm_app_app-root_language_icon">language</mat-icon>
                    <h3 matListItemTitle *ngIf="adminMenuExpand">{{'application.language' | translate}}</h3>
                </mat-list-item>

                <mat-list-item [matMenuTriggerFor]="menu">
                    <mat-icon matListItemIcon vcldautomationid="adm_app_app-root_power-settings_icon">power_settings_new</mat-icon>
                    <h3 matListItemTitle *ngIf="adminMenuExpand">{{'components.buttons.logout' | translate}}</h3>
                </mat-list-item>

                <mat-list-item (click)="adminMenuExpand = !adminMenuExpand">
                    <mat-icon matListItemIcon vcldautomationid="adm_app_app-root_chevron-expand_icon">
                        {{ adminMenuExpand ? 'chevron_left' : 'chevron_right'}}
                    </mat-icon>
                    <h3 matListItemTitle class="version" *ngIf="adminMenuExpand">{{"application.version" | translate}}
                        {{versionNumber}}</h3>
                </mat-list-item>
            </div>

        </mat-action-list>
    </mat-drawer>

    <mat-drawer-content style="background: white;" class="menuHelper" (scroll)="removeElement()" >
        <router-outlet></router-outlet>
    </mat-drawer-content>

</mat-drawer-container>