import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'vcld-adm-color-input',
  templateUrl: './color-input.component.html',
  styleUrls: ['./color-input.component.scss']
})

export class ColorInputComponent implements OnInit {


  @Input()
  public enabled: boolean = false;

  @Input()
  public disabled: boolean = false;

  @Output()
  public onChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public ngOnInit() {

  }

  public onCheckboxChanged(color: MatCheckboxChange): void {
    this.onChange.emit(color.checked);
  }

}