<div class="dialog-header">
    <p mat-dialog-title class="bold">{{ 'components.dialogs.about.title' | translate}}</p>
    <button mat-icon-button class="close-btn" [mat-dialog-close]="true">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <div class="manufacture">
        <img src="../../../assets/images/icon_manufacturer.png" alt="Manufacturer Icon">
        <div>
            <p>{{ 'components.dialogs.about.organization' | translate}}</p>
            <p>{{ 'components.dialogs.about.street' | translate}}</p>
            <p>{{ 'components.dialogs.about.city' | translate}}</p>
            <p>{{ 'components.dialogs.about.country' | translate}}</p>
        </div>
    </div>
    <p class="bold">{{ 'components.dialogs.about.version' | translate}} {{version}}</p>
    <div class="ref-material">
        <img src="../../../assets/images/icon_ref.png" width="36px" alt="REF Icon">
        <p>{{materialNumber}}</p>
    </div>
</mat-dialog-content>