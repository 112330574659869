import { Injectable } from '@angular/core';
import { RoleAssignment, SinglePermission } from 'visauto-auth';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';

import { AdminAppEnvironment as environment } from 'visenvironment';
import { filter, tap, first, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SecurityService {

    public permissions$: BehaviorSubject<RoleAssignment> = new BehaviorSubject(null);

    private baseUrl: string;
    public showAnnouncementBanner: boolean = true;

    constructor(private http: HttpClient, private oAuth: OAuthService) {
        this.baseUrl = environment.connectivity.securityEndpoint;
    }

    public resolvePermissionsByRole(useSessionCache?: boolean): Observable<RoleAssignment> {
        const cached = this.getPermissionsFromCache(useSessionCache);
        if (cached == null) {
            return this.downloadPermissions();
        }

        return cached;
    }

    public async canSeeAsync(permissionKey: string, useSessionCache?: boolean): Promise<boolean> {
        const role = await this.resolvePermissionsByRole(useSessionCache).toPromise();
        const perm = role.permissions.find(p => p.key === permissionKey);
        return (perm !== undefined);
    }

    public disposePermissionsStore() {
        // console.log(`[Security Service] Cleanup Permission Store...`);
        this.permissions$.complete();
        // console.log(`[Security Service] Cache observable completed => [OK]`);
        sessionStorage.removeItem('admperm');
        // console.log(`[Security Service] Deleted cached permissions from storage => [OK]`)
    }

    private downloadPermissions(): Observable<RoleAssignment> {
        // console.log(`[Security Service] Downloading new version of the permissions module!`);
        return this.http.get<RoleAssignment[]>(`${this.baseUrl}/role-permissions`, { headers: { Authorization: `Bearer ${this.oAuth.getIdToken()}` } })
            .pipe(map(this.combineRoleAssigments))
            .pipe(tap(r => this.permissions$.next(r)))
            .pipe(tap(r => console.log(`[Security Service] [Response] Received Role from Backend: ${r.roleId}`)))
            .pipe(tap((r) => {
                sessionStorage.setItem('admperm', JSON.stringify(r));
                // console.log(`[Security Service] Caching received permissions: updated ${r.permissions.length} permissions!`);
            }));
    }

    private getPermissionsFromCache(useSessionCache?: boolean): Observable<RoleAssignment> | null {
        if (this.permissions$.getValue() != null) {
            // console.log(`[Security Service] Using cached version of the permissions module!`);
            return this.permissions$.pipe(first());
        } else if (sessionStorage.getItem('admperm') != null && useSessionCache) {
            // console.log(`[Security Service] Using cached version from sessionStorage for the permissions module!`);
            this.permissions$.next(JSON.parse(sessionStorage.getItem('admperm')));
            return this.permissions$.pipe(first());
        }
    }

    private combineRoleAssigments(roles: RoleAssignment[]): RoleAssignment {
        const tempRole = new RoleAssignment();
        tempRole.id = "CombinedRole";
        tempRole.roleId = "vis-cbd-role";
        tempRole.permissions = [];
        roles.forEach(role => {
            tempRole.permissions.push(...role.permissions);
        });
        const s = new Set<SinglePermission>(tempRole.permissions);
        tempRole.permissions = Array.from(s);
        return tempRole;
    }

}