import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { StoreSettingsPageComponent } from '../pages/store-settings/store-settings.page.component';

@Injectable({
    providedIn:'root'
})
export  class CheckPendingChangesGuard  
{

    canDeactivate(component: StoreSettingsPageComponent): boolean | Observable<boolean> |Promise<boolean> {
        // if there are changes send confirmation dialog | otherwise allow deactivate
        return component.validateSettingsPage();
    }
}