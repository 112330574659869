import { RoleAssignment } from 'visauto-auth';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SecurityService } from '../services/security.service';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({ providedIn: 'root' })
export class SecurityResolver  {

    constructor(private security: SecurityService, private oAuth: OAuthService) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<RoleAssignment> | Promise<RoleAssignment> | RoleAssignment {
        if (this.oAuth.getIdToken() != null || this.oAuth.getIdToken() != undefined) {
            // console.log(`[Security Resolver] Start resolving the permissions module.`);
            return this.security.resolvePermissionsByRole();
        }
        // console.log(`[Security Resolver] No token in cache! couldn't load permissions module.`);
        return null;
    }
}
