import { VisucloudPermissionCategories } from './vis-permission-category.enum';

export class VisucouldPermission {

    constructor(permissionName: string, category: VisucloudPermissionCategories) {
        this.permissionName = permissionName;
        this.permissionCategory = category;
    }

    private readonly permissionName: string;

    private readonly permissionCategory: VisucloudPermissionCategories;

    // returns the permission key
    public get Permission() {
        return this.permissionName;
    }

    // returns one of the permission categories <canSee, haveAccess, doAction>
    public get Category() {
        return this.permissionCategory;
    }
}
