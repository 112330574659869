/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PendingChangesGuard } from 'utilities';

import { LandingComponent } from './app-admin/components/landingComponent/landing.component';

import { NotFoundComponent } from 'utilities';
import { SecurityGuard } from './app-admin/guards/security.guard';
import { SecurityResolver } from './app-admin/resolvers/security.resolver';
import { AvatarSessionsPageComponent } from './app-admin/pages/avatar-sessions/avatar-sessions.page.component';
import { RoleManagementPageComponent } from './app-admin/pages/role-management/role-management.page.component';
import { StoreSettingsPageComponent } from './app-admin/pages/store-settings/store-settings.page.component';
import { CentrationOverviewPageComponent } from './app-admin/pages/centration-overview/centration-overview.page.component';
import { CentrationDetailsPageComponent } from './app-admin/pages/centration-details/centration-details.page.component';
import { LogoutComponent } from './app-admin/components/logoutComponent/logout.component';
import { OnboardingWizardPageComponent } from './app-admin/pages/onboarding-wizard/onboarding-wizard.page.component';
import { AdminOverviewPageComponent } from './app-admin/pages/admin-overview/admin-overview.page.component';
import { DigitalMarketingToolModule } from './app-dmt/dmt.module';
import { AvatarSessionOverviewPageCoponent } from './app-admin/pages/avatar-session-overview/avatar-session-overview.page.component';
import { UnaccessedAvatarSessionsPageComponent } from './app-admin/pages/unaccessed-avatar-sessions/unaccessed-avatar-sessions.page.component';
import { FeatureFlagGuard } from './guards/featureFlag.guard';
import {CheckPendingChangesGuard}from './app-admin/guards/CheckPendingChanges.guard';
import { PermissionCheckPageComponent } from './app-admin/pages/permission-check/permission-check.page.component';

const routes: Routes = [
  {
    path: 'marketing',
    loadChildren: () => import('./app-dmt/dmt.module').then(m => m.DigitalMarketingToolModule),
    canActivate: [FeatureFlagGuard],
    data: { permission: 'admin_view_marketing', requiredFeatureFlag: 'DMTEnabled', featureFlagRedirect: '/home' }
  },
  {
    path: '',
    component: LandingComponent,
    resolve: {
      Permissions: SecurityResolver
    }
  },
  {
    path: 'after',
    component: PermissionCheckPageComponent,
    resolve: {
      Permissions: SecurityResolver
    }
  },
  {
    path: 'home',
    component: AdminOverviewPageComponent,
    pathMatch: 'full'
  },
  {
    path: 'logout',
    component: LogoutComponent,
    pathMatch: 'full'
  },
  {
    path: 'avatar-overview',
    component: AvatarSessionOverviewPageCoponent,
    data: { Permission: 'admin_view_avatarsessionlist'},
    pathMatch: 'full',
    canActivate: [SecurityGuard]
  },
  {
    path: 'accessed-avatars',
    component: AvatarSessionsPageComponent,
    data: { permission: 'admin_view_avatarsessionlist' },
    pathMatch: 'full',
    canActivate: [SecurityGuard]
  },
  {
    path: 'unaccessed-avatars',
    component: UnaccessedAvatarSessionsPageComponent,
    data: { permission: 'admin_view_avatarsessionlist' },
    pathMatch: 'full',
    canActivate: [SecurityGuard]
  },
  {
    path: 'centration/:centrationJobId',
    component: CentrationDetailsPageComponent,
    data: { permission: 'admin_view_centration' },
    pathMatch: 'full',
    canActivate: [SecurityGuard]
  },
  {
    path: 'centration',
    component: CentrationOverviewPageComponent,
    data: { permission: 'admin_view_centration' },
    pathMatch: 'full',
    canActivate: [SecurityGuard]
  },
  {
    path: 'store-settings',
    component: StoreSettingsPageComponent,
    pathMatch: 'full',
    data: { permission: 'admin_view_ecpsettings' },
    canActivate: [SecurityGuard],
    canDeactivate: [CheckPendingChangesGuard]
  },
  {
    path: 'onboarding-wizard',
    component: OnboardingWizardPageComponent,
    pathMatch: 'full',
    data: { permission: 'admin_view_onboarding' },
    canActivate: [SecurityGuard]
  },
  {
    path: 'app-settings',
    component: RoleManagementPageComponent,
    pathMatch: 'full',
    data: { permission: 'admin_full_roleassignments' },
    canActivate: [SecurityGuard],
    canDeactivate: [PendingChangesGuard]
  },
  {
    path: '404-not-found',
    component: NotFoundComponent
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
