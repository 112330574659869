import { AvatarSource } from './../../../../../../ConsumerPortal/src/app/vto/models/avatarcreationsession';
import { Component, AfterViewInit, ViewChild, ErrorHandler, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AvatarCreationSession } from '../../typings/avatar-creation-session';
import { startWith, switchMap, catchError, map, tap } from 'rxjs/operators';
import { of, merge, Observable } from 'rxjs';
import { AvatarSessionService, Store } from '../../services/admin-avatar-session.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { EmailService } from '../../services/email.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../../components/snackbar/snackbar.component';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';


@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'vis-adm-unacs-session-page',
    templateUrl: './unaccessed-avatar-sessions.page.component.html',
    styleUrls: ['./unaccessed-avatar-sessions.page.component.scss']
})
export class UnaccessedAvatarSessionsPageComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    public isLoading = true;

    public unaccessed: SafeUrl = null;

    public dataSrc: AvatarCreationSession[];
    public dataSrcTotal = 0;


    public readonly displayedColumns: string[] = [
        'email',
        'id',
        'uploadedAt',
        'action'
    ];

    public multipleStores$: Observable<boolean> = this.avSession.stores$.pipe(
        map(stores => stores.length > 1)
    );

    public selectedStore$ = this.avSession.selectedStore$;
    public stores$ = this.avSession.stores$;

    private readonly EMAIL_REGEX: RegExp = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i;

    private avatarSource: string = null;
    public isCameronSource: boolean = false;

    constructor(
        public avSession: AvatarSessionService,
        private dom: DomSanitizer,
        private mail: EmailService,
        private snack: MatSnackBar,
        private translate: TranslateService,
        private errorHandler: ErrorHandler,
        private route: ActivatedRoute,
        private renderer:Renderer2
    ) { }

    public async ngOnInit() {
        this.avatarSource = this.route.snapshot?.queryParams?.source;
        if (this.avatarSource)
            this.isCameronSource = this.avatarSource.toLowerCase() === AvatarSource.CAMERON.toLowerCase()? true : false;

        if(this.avSession.stores$.pipe(map((stores) => stores?.length === 0))) {
            await this.avSession.fetchStores();
        }        
    }

    public async ngAfterViewInit() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.pageIndex = 0;
        });

        await this.fetch();
    }

    public async fetch() {
        await merge(this.sort.sortChange, this.paginator.page).pipe(startWith({}), switchMap(() => {
            this.isLoading = true;
            return this.avSession.getAvatarSessions(this.paginator.pageSize, this.paginator.pageIndex, this.sort.active, this.sort.direction, false, this.avatarSource);
        }), map(data => {
            this.dataSrc = data.items;
            this.dataSrcTotal = data.total;
            this.isLoading = false;
            return data.items;
        }), catchError(() => {
            this.isLoading = false;
            return of([]);
        })).toPromise();
    }

    public async changeStore(e) {
        this.paginator.pageIndex = 0;
        this.avSession.selectedStore$.next(e);
        await this.fetch();
    }

    downloadUnacs() {
        this.avSession.downloadACSFile(false, this.avatarSource)
            .subscribe(blob => {
                const a = this.renderer.createElement('a')
                const objectUrl = URL.createObjectURL(blob)
                a.href = objectUrl
                a.download = `${this.translate.instant("pages.avatar-sessions.unaccessed")}.csv`;;
                a.click();
                URL.revokeObjectURL(objectUrl);
            })
    }

    public async resendInvitation(opticianId: string, sessionId: string, mail: string) {
        this.dataSrc.find(s => s.id === sessionId).resendTriggered = true;

        try {
            await this.mail.resendInvitation(opticianId, sessionId);

            this.setResendDate(sessionId, new Date(Date.now()));

            this.snack.openFromComponent(SnackbarComponent, {
                data: {
                    icon: 'check',
                    heading: "errors.success",
                    message: "pages.avatar-sessions.resend_msg",
                    messageParams: {mail: mail},
                    btnIcon: 'close'
                },
                panelClass: ["error-panel","green-success"],
                verticalPosition: 'bottom',
                horizontalPosition: 'center'
            });
        } catch (error) {
            this.errorHandler.handleError(error);
            this.setResendDate(sessionId, null);
            this.dataSrc.find(s => s.id === sessionId).resendTriggered = false;
        }
    }

    public resendAvailable(mail: string):boolean {
        return this.EMAIL_REGEX.test(mail);
    }

    public setResendDate(sessionId: string, date: Date) {
        const old = this.dataSrc.find(s => s.id === sessionId);
        old.resendDate = date;
        this.dataSrc.splice(this.dataSrc.findIndex(s => s.id === sessionId), 1);
        this.dataSrc.push(old);
    }

    public async resendInvitationSMS(opticianId: string, sessionId: string, phone: string) {
        this.dataSrc.find(s => s.id === sessionId).resendTriggered = true;

        try {
            await this.mail.resendInvitation(opticianId, sessionId);
            this.setResendDate(sessionId, new Date(Date.now()));

            this.snack.openFromComponent(SnackbarComponent, {
                data: {
                    icon: 'check',
                    heading: "errors.success",
                    message: "pages.avatar-sessions.resend_sms_msg",
                    messageParams: {phone: phone},
                    btnIcon: 'close'
                },
                panelClass: ["error-panel","green-success"],
                verticalPosition: 'bottom',
                horizontalPosition: 'center'
            });
        } catch (error) {
            this.errorHandler.handleError(error);
            this.setResendDate(sessionId, null);
            this.dataSrc.find(s => s.id === sessionId).resendTriggered = false;
        }
    }

    ngOnDestroy(): void {
        this.avSession.resetStoreSelection();
    }
}
