/* eslint-disable max-len */
// Angular Core Modules
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS,HttpClientModule } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';

// Material Imports
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import * as WebFont from 'webfontloader';

// Translation and Localization
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AdminAppEnvironment as env} from 'visenvironment';

// Angular Core Components
import { AppComponent } from './app-admin/components/appComponent/app.component';

// Visucloud Vendors Module (Angular/Material/Components)
import { VISVendorsModule } from 'visvendors';

// VisuCloud Security Module (Auto | Auth)
import { VISAutoAuthModule } from 'visauto-auth';

// VisuCloud Support Module (Contact Card)
// import { VISSupportModule } from 'vissupport';

// VisuCloud Utilities (PageNotFound, Error-Handling)
import { UtilitiesModule } from 'utilities';

// VisuCloud Environment Variables
import { VISEnvironmentModule } from 'visenvironment';

// Admin Portal Components
import { LandingComponent } from './app-admin/components/landingComponent/landing.component';

import { DigitalMarketingToolModule } from './app-dmt/dmt.module';
import { AdminSharedModule } from './shared/admin-shared.module';

// Role Management Components
import { PermissionKeyDirective } from './app-admin/directives/secured.directive';
import { SecurityGuard, PermissionGuard } from './app-admin/guards/security.guard';
import { AvatarSessionsPageComponent } from './app-admin/pages/avatar-sessions/avatar-sessions.page.component';
import { RoleManagementPageComponent } from './app-admin/pages/role-management/role-management.page.component';
import { StoreSettingsPageComponent } from './app-admin/pages/store-settings/store-settings.page.component';
import { AvatarSessionDeleteDialogComponent } from './app-admin/dialogs/avatar-session-delete/avatar-session-delete.dialog.component';
import { LanguageSelectionDialogComponent } from './app-admin/dialogs/language-selection/language-selection.dialog.component';
import { MatTableModule } from '@angular/material/table';
import { CentrationOverviewPageComponent } from './app-admin/pages/centration-overview/centration-overview.page.component';
import { CentrationDetailsPageComponent } from './app-admin/pages/centration-details/centration-details.page.component';
import { JobStatusPipe } from './app-admin/pipes/centration-job-status.pipe';
import { JobSourcePipe } from './app-admin/pipes/centration-job-source.pipe';
import { JobTypePipe } from './app-admin/pipes/centration-job-type.pipe';
import { LogoutComponent } from './app-admin/components/logoutComponent/logout.component';
import { OnboardingWizardPageComponent } from './app-admin/pages/onboarding-wizard/onboarding-wizard.page.component';
import { AboutDialogComponent } from './app-admin/dialogs/about/about.dialog.component';
import { FAQDialogComponent } from './app-admin/dialogs/FAQ/faq.page.component';
import { StoreSettingsComponent } from './app-admin/components/store-settings/store-settings.component';
import { EmailInputComponent } from './app-admin/components/email-input/email-input.component';
import { ECPLogoInputComponent } from './app-admin/components/logo-input/logo-input.component';
import { AdminOverviewPageComponent } from './app-admin/pages/admin-overview/admin-overview.page.component';
import { PricingInputComponent } from './components/pricing-input/pricing-input.component';
import { ModelNameInputComponent } from './components/model-name-input/model-name-input.component';
import { MaterialInputComponent } from './components/material-input/material-input.component';
import { ColorInputComponent } from './components/color-input/color-input.component';
import { SizeInputComponent } from './components/size-input/size-input.component';
import { AdminErrorHandler } from './handler/adminErrorHandler.service';
import { AvatarSessionOverviewPageCoponent } from './app-admin/pages/avatar-session-overview/avatar-session-overview.page.component';
import { UnaccessedAvatarSessionsPageComponent } from './app-admin/pages/unaccessed-avatar-sessions/unaccessed-avatar-sessions.page.component';
import { SnackbarComponent } from './app-admin/components/snackbar/snackbar.component';
import { DemoAvatarUploadDialogComponent } from './app-admin/dialogs/demo-avatar-upload/demo-avatar-upload.dialog.component';
import { AppConfigService } from './handler/appConfig.service';
import {FeatureFlagDirective } from './directives/featureFlag.directive';
import { FeatureFlagGuard } from './guards/featureFlag.guard';
import { FormatPricePipe } from './app-admin/pipes/format-price.pipe';
import { InvitationMailHostComponent } from "./app-admin/components/invitation-mail-host/invitation-mail-host.component";
import { InvitationLanguageChangeDialogComponent } from "./app-admin/dialogs/invitation-language-change/invitation-language-change.dialog.component";

import { UnsavedSettingsChangeDialogComponent } from './app-admin/dialogs/unsaved-changes/unsaved-settings-changes.dialog.component';

import { InvitationMailPreviewDialogComponent } from './app-admin/dialogs/invitation-mail-preview-dialog/invitation-mail-preview-dialog.component';

import { PermissionCheckPageComponent} from './app-admin/pages/permission-check/permission-check.page.component';


export function initialitzeRuntimeAppConfig(appConfig: AppConfigService) {
  WebFont.load({
      google: {
          families: [
              'Material Icons',
          ],
      },
  });

  return () => appConfig.initializeAppConfig();
}

@NgModule({
  declarations: [
    // Components
    AppComponent,
    LandingComponent,
    LogoutComponent,
    StoreSettingsComponent,
    EmailInputComponent,
    InvitationMailHostComponent,
    // ECPLogoInputComponent,
    PricingInputComponent,
    ModelNameInputComponent,
    MaterialInputComponent,
    ColorInputComponent,
    SizeInputComponent,
    SnackbarComponent,
    // Directives
    PermissionKeyDirective,
    FeatureFlagDirective,
    // Pages
    AvatarSessionsPageComponent,
    RoleManagementPageComponent,
    StoreSettingsPageComponent,
    CentrationOverviewPageComponent,
    CentrationDetailsPageComponent,
    OnboardingWizardPageComponent,
    AdminOverviewPageComponent,
    AvatarSessionOverviewPageCoponent,
    UnaccessedAvatarSessionsPageComponent,
    PermissionCheckPageComponent,
    // Dialogs
    AvatarSessionDeleteDialogComponent,
    LanguageSelectionDialogComponent,
    AboutDialogComponent,
    FAQDialogComponent,
    DemoAvatarUploadDialogComponent,
    InvitationLanguageChangeDialogComponent,
    UnsavedSettingsChangeDialogComponent,
    // Pipes
    JobStatusPipe,
    JobSourcePipe,
    JobTypePipe,
    FormatPricePipe,
    InvitationMailPreviewDialogComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    // MatTableModule,
    AdminSharedModule
  ],
  providers: [
    HttpClient, 
    SecurityGuard, 
    PermissionGuard, 
    {provide: ErrorHandler, useClass: AdminErrorHandler}, 
    {provide: APP_INITIALIZER, useFactory: initialitzeRuntimeAppConfig, deps: [AppConfigService], multi: true},
    FormatPricePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, env.connectivity.translations);
}
