/* eslint-disable @angular-eslint/component-selector */
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdminAppEnvironment as environment } from 'visenvironment';

@Component({
  selector: 'vcloud-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent {

  private queryFlag: string;

  constructor(private route: ActivatedRoute) {
    this.queryFlag = this.route.snapshot.queryParams.r;
  }

  public get zeissLogo() {
    return environment.connectivity.zeiss_logo;
  }

  public get isReturned() {
    return this.queryFlag === 'after';
  }
}
