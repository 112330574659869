<div class="dialog-header icon-centering">
    <mat-icon class="icon"> warning </mat-icon>
    <p mat-dialog-title class="bold" vcldAutomationId="adm_dmt_vcld-adm-unsaved-settings-change_unsaved-changes-header_txt">{{'components.dialogs.unsaved-change.title' | translate}}</p>
</div>
<mat-dialog-content>
    <div class="centering" vcldAutomationId="adm_dmt_vcld-adm-unsaved-settings-change_unsaved-changes-content_txt">
        <p>{{'components.dialogs.unsaved-change.subtitle-1' | translate }} </p>
    </div>
</mat-dialog-content>
<mat-dialog-actions [align]="'center'">
    <button mat-stroked-button color="primary" [mat-dialog-close]="true" vcldAutomationId="adm_dmt_vcld-adm-unsaved-settings-change_discard-changes_btn"> {{'components.buttons.discard' |translate}}</button>
    <button mat-flat-button color="primary" [mat-dialog-close]="true" (click)="saveAll()" vcldAutomationId="adm_dmt_vcld-adm-unsaved-settings-change_save-all-changes_btn">{{'components.buttons.saveAll' | translate}} </button>
</mat-dialog-actions>