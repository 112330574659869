import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'formatPrice'
})
export class FormatPricePipe implements PipeTransform {
  constructor(private translate: TranslateService) { }
  transform(value:number): string {
    if(isNaN(value))
        return value.toString();

    return new Intl.NumberFormat(this.translate.currentLang).format(value);
  }
}