<div class="dialog-header title-wrapper">
  <p mat-dialog-title class="title">{{ "components.dialogs.language-selection.select_language" | translate }}</p>
  <button vcldAutomationId="consr_vis-adm-language-dialog_close-language_btn" class="close-btn" mat-icon-button [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
  </button>
</div>


<mat-dialog-content (window:resize)="recalculateRows()">
  <div class="languages">
    <div class="language-wrapper" *ngFor="let langRow of (languageMap$ | async)">
      <a vcldAutomationId="consr_vis-adm-language-dialog_change-language-to_link" *ngFor="let lang of langRow" class="cyan-text language" (click)="useLanguage(lang.code)"
        [mat-dialog-close]="true" [ngClass]="{ selected: translate.currentLang === lang.code }">
        {{ lang.name }}
      </a>
    </div>
  </div>
</mat-dialog-content>