<!-- Single line Textfield -->
<div class="col s12" *ngIf="isText">
    <mat-form-field class="col s12">
        <mat-label>{{name | translate}}</mat-label>
        <input matInput type="text" vcldAutomationId="adm_app_vcld-adm-invitation-mail-component-host_custom-invitation-mail_input-field" placeholder="" (change)="valuechanged()" [(ngModel)]="value" [readonly]="readOnly" [disabled]="readOnly">
    </mat-form-field>
</div>

<!-- Multiline textfield -->
<div class="col s12" *ngIf="isTextarea">
    <mat-form-field class="col s12" style="height: auto;">
        <mat-label>{{name | translate}}</mat-label>
        <textarea matInput cdkTextareaAutosize type="text" vcldAutomationId="adm_app_vcld-adm-invitation-mail-component-host_custom-invitation-mail-txt-area_input-field" placeholder=""
            [(ngModel)]="value" [readonly]="readOnly" (change)="valuechanged()" [disabled]="readOnly"></textarea>
    </mat-form-field>
</div>