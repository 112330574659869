import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CentrationService } from '../../services/centration.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'vcld-centration-page',
    templateUrl: 'centration-overview.page.component.html',
    styleUrls: ['./centration-overview.page.component.scss']
})
export class CentrationOverviewPageComponent implements OnInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    public dataSrc;
    public dataSrcTotal = 0;

    public readonly displayedColumns: string[] = [
        'id',
        'sessionId',
        'createdAt',
        'source',
        'jobType',
        'status',
        'action'
    ];

    constructor(
        private readonly centration: CentrationService,
        private readonly router: Router,
        private translate: TranslateService
    ) {
        this.dataSrc = this.centration.getCentrationJobs().pipe(
            startWith(null)
        );
    }

    ngOnInit(): void { }

    public ngAfterViewInit() {
    }

    public startReview(centrationJobId: string) {
        this.router.navigate(['centration', centrationJobId]);
    }

    public getStatusCSS(status): string {
        switch (status) {
            case 0:
                return 'not-started';
            case 1:
                return 'pending';
            case 2:
                return 'done';
            case 3:
                return 'rejected';
            default:
                return 'error';
        }
    }

    getStatusTooltip(status) {
        switch (status) {
            case 0:
                return 'tooltips.centration_overview.status_not_started';
            case 1:
                return 'tooltips.centration_overview.status_pending';
            case 2:
                return 'tooltips.centration_overview.status_done';
            case 3:
                return 'tooltips.centration_overview.status_rejected';
            default:
                return 'error';
        }
    }
}
