import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Observable } from "rxjs";
import { InvitationMailComponent, InvitationMailDatabaseComponent } from '../../models/invitation-mail-template.model';

@Component({
  selector: 'vcld-adm-invitation-mail-component-host',
  templateUrl: './invitation-mail-host.component.html',
  styleUrls: ['./invitation-mail-host.component.scss']
})
export class InvitationMailHostComponent implements OnInit {

  @Input()
  public component: InvitationMailComponent;

  @Input()
  public componentList: Observable<InvitationMailDatabaseComponent[]>;

  private _component: InvitationMailDatabaseComponent;

  @Input()
  public index: number;

  @Input()
  public canEdit: boolean = false;

  @Output()
  public valueChanged: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  public get type() {
    return this._component?.type;
  }

  public get isText(): boolean {
    return this.type == 'TEXT';
  }

  public get isTextarea(): boolean {
    return this.type == 'TEXTAREA';
  }

  public get name(): string {
    let transformedName = this._component.name.replace(/\s/g, "").toLowerCase();
    return (`components.email-host.${transformedName}`);
  }

  public get readOnly(): boolean {
    return this.component.readOnly || !(this.canEdit);
  }

  public get value(): string {
    return this.component.value;
  }

  public set value(value: string) {
    this.component.value = value;
  }
  public valuechanged(){
    this.valueChanged.emit(true);
  }

  public async ngOnInit() {
    this._component = (await this.componentList.toPromise()).find(c => c.id === this.component.id);
  }

}