import { Component, ElementRef, ErrorHandler, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild,ChangeDetectorRef } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PreferencesService } from '../../services/preferences.service';

@Component({
    selector: "vcld-banner-input",
    templateUrl: "./banner-input.component.html",
    styleUrls: ["./banner-input.component.scss"],
})
export class BannerInputComponent implements OnInit, OnChanges {
    public loading: boolean = true;

    public changed: boolean = false;

    public customBanner$: Observable<SafeUrl>;

    public get isDisabled(): boolean {
        if (this.changed && !this.loading) {
            return this.bannerUploaded;
        }
        if (this.loading) {
            return true;
        }
        return true;
    }

    @Input()
    public opticianId: string;

    @Input()
    public canEdit: boolean = true;

    @Input()
    public index:number;

    @Output()
    public bannerExists: EventEmitter<boolean> = new EventEmitter();

    @Output()
    public fileInputChange: EventEmitter<File> = new EventEmitter();

    @Output()
    public bannerDeleted: EventEmitter<any> = new EventEmitter();

    @Output()
    public bannerSaved: EventEmitter<any> = new EventEmitter();

    public fileInput: FileList;

    public bannerUploaded: boolean = false;

    public aspectRatioRestriction$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);

    constructor(
        private pref: PreferencesService,
        private dom: DomSanitizer,
        private errorHandler: ErrorHandler,
        private cdr: ChangeDetectorRef
    ) {}

    public ngOnInit() {
        this.changed = false;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        const change = changes["opticianId"]?.currentValue;
        if (change !== null && change !== undefined) {
            this.loadBanner();
        }
    }

    public async fileInputChanged(eventData: FileList) {
        this.changed = true;
        this.fileInput = eventData;
        this.bannerUploaded = false;
        const file = this.fileInput[0];
        if (file) {
            const fr = new FileReader();
            fr.onload = () => {
                const image = new Image();
                image.onload = () => {
                    let ratio = image.width / image.height;
                    let isUploadEnabled = ratio >= 1.6 && ratio <= 2.0;
                    this.aspectRatioRestriction$.next(isUploadEnabled);
                    this.pref.aspectRatioRestriction$.next(isUploadEnabled);
                    isUploadEnabled ? this.pref.bannerImageUploadFlagUpdate(true,this.index) : this.pref.bannerImageUploadFlagUpdate(false,this.index)
                };
                image.src = fr.result as string;
            };
            fr.readAsDataURL(file);
        }
        this.fileInputChange.emit(file);
    }

    public saveBanner(e: Event): void {
        this.loading = true;
        (e.target as HTMLButtonElement).disabled = true;
        const file = this.fileInput[0];
        this.customBanner$ = null;
        this.bannerUploaded = true;
        this.pref
            .setCustomBanner(file, this.opticianId)
            .toPromise()
            .then(() => {
                this.loadBanner();
                this.bannerSaved.emit();
            })
            .catch((err) => {
                this.errorHandler.handleError(err);
                this.loading = false;
            });
    }

    public removeBanner(e: Event) {
        e.preventDefault();
        this.loading = true;
        this.customBanner$ = null;
        (e.target as HTMLButtonElement).disabled = true;
        this.pref
            .removeCustomBanner(this.opticianId)
            .toPromise()
            .then((_) => {
                this.loadBanner();
                this.bannerDeleted.emit();              
            })
            .catch((e) => {
                this.errorHandler.handleError(e);
                this.loading = false;
            });
            this.cdr.detectChanges();
    }

    public onClickEvent(event: Event) {
        event.preventDefault();
    }

    private loadBanner() {
        this.customBanner$ = this.pref
            .getBannerImage("custom", this.opticianId)
            .pipe(
                map((blob) =>
                    blob != null ? this.dom.bypassSecurityTrustUrl(blob) : null
                ),
                tap((blob) => {
                    setTimeout(() => {
                        this.loading = false;
                        this.bannerExists.emit(blob != null);
                    }, 100);
                })
            );
    }
}
