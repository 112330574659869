import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'vcld-adm-pricing-input',
  templateUrl: './pricing-input.component.html',
  styleUrls: ['./pricing-input.component.scss']
})

export class PricingInputComponent implements OnInit {


  @Input()
  public enabled: boolean = false;

  @Input()
  public disabled: boolean = false;

  @Output()
  public onChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public ngOnInit() {

  }

  public onCheckboxChanged(pricing: MatCheckboxChange): void {
    this.onChange.emit(pricing.checked);
  }
}