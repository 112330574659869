import { HttpClient } from '@angular/common/http';
import { AdminAppEnvironment } from 'visenvironment';
import { OAuthService } from 'angular-oauth2-oidc';
import { Injectable } from '@angular/core';

@Injectable({providedIn: "root"})

export class DemoAvatarUploadService {
    constructor(
        private http: HttpClient,
        private readonly oAuth: OAuthService,
    ){}

    public uploadDemoAvatar(consumerId: string, storeId: string, customerNumber: string, cameronAatar?: boolean) {
        const uri = `${AdminAppEnvironment.connectivity.demo_avatar}${cameronAatar ? '?cameron=true' : ''}`; 
        return this.http.post(uri, {
            consumerId,
            opticianId: storeId,
            customerNumber,
            favoritedFrames: []
        }, {
            headers: {
                Authorization: `Bearer ${this.oAuth.getIdToken()}`
            }
        })
    }
}