import { Component, OnInit } from '@angular/core';
import { CentrationService } from '../../services/centration.service';
import { Observable } from 'rxjs';
import { CentrationResult } from '../../models/centration-result.model';
import { CentrationDetails } from '../../models/centration-details.model';
import { ActivatedRoute, Router } from '@angular/router';
import { map, mergeMap, observeOn, tap } from 'rxjs/operators';
import { CentrationJobStatus } from '../../models/centration-job.model';
import { CentrationJob } from '../../models/centration-job.model';

@Component({
    selector: 'vcld-centration-details-page',
    templateUrl: './centration-details.page.component.html',
    styleUrls: ['./centration-details.page.component.scss']
})
export class CentrationDetailsPageComponent implements OnInit {

    public isLoading: boolean = false;

    public centrationResult$: Observable<CentrationResult>;
    public centrationDetails$: Observable<CentrationDetails>;
    public centrationJob$: Observable<CentrationJob>;
    public centrationJobId: string;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly centration: CentrationService,
        private readonly router: Router
    ) {
        this.centrationResult$ = route.params.pipe(
            tap(p => this.centrationJobId = p.centrationJobId),
            mergeMap(p => centration.getCentrationResult(p.centrationJobId))
        );

        this.centrationDetails$ = route.params.pipe(
            tap(p => this.centrationJobId = p.centrationJobId),
            mergeMap(p => centration.getCentrationDetails(p.centrationJobId))
        );

        this.centrationJob$ = route.params.pipe(
            tap(p => this.centrationJobId = p.centrationJobId),
            mergeMap(p => centration.getCentrationJobs()),
            map(jobs => jobs.filter(job => job.id === this.centrationJobId).pop())
        );
    }

    ngOnInit(): void { }

    public async updateStatus(status: boolean) {
        await this.centration.updateCentrationJob(this.centrationJobId, status ? CentrationJobStatus.APPROVED : CentrationJobStatus.REJECTED).toPromise();
        this.router.navigate(['centration']);
    }

    public getId(): string {
        return window.location.href.split('/').pop();
    }
}
