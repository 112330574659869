<div class="row">
     <!-- Close button -->
     <mat-icon class="dmt-close-icon" routerLink="/home">close</mat-icon>

    <div class="col s10 offset-s1 small-page-fix" style="margin-top: 64px;">
        <div class="col s12">
            <p class="adm-title">{{'pages.onboarding_wizard.title' | translate}}</p>
        </div>

        <div class="col s12">
            <p class="page-description bold">
                {{'pages.onboarding_wizard.subline' | translate}}
            </p>
        </div>

        <div class="col s12">
            <div class="col s12">
                <p>{{ "pages.onboarding_wizard.customer_no" | translate }}</p>
                <mat-form-field appearance="outline" class="col s4">
                    <mat-label>{{"components.inputs.optician_no" | translate }}</mat-label>
                    <input [value]="opticianCustomerNumber" (change)="changeCustomerNumber($event.target.value)" #icn
                        matInput type="text" placeholder="">
                </mat-form-field>
            </div>
            <div class="col s12">
                <p>{{"pages.onboarding_wizard.optician_id" | translate}}</p>
                <mat-form-field appearance="outline" class="col s4">
                    <mat-label>{{"components.inputs.optician_id" | translate }}</mat-label>
                    <input [value]="opticianId" (change)="changeOpticianId($event.target.value)" #ioi matInput
                        type="text" placeholder="">
                </mat-form-field>
            </div>

            <button mat-flat-button color="primary" [disabled]="icn.value == '' || ioi.value == ''"
                class="col s6 m4 l2 xl2" (click)="initiateOnboarding(icn.value, ioi.value)">
                {{"components.buttons.initiate" | translate}}
            </button>
        </div>
    </div>
</div>