
import { AuthConfig } from 'angular-oauth2-oidc';
import { AdminAppEnvironment as environment } from 'visenvironment';

export const authConfig: AuthConfig = {
    clientId: environment.authentication.clientId,
    redirectUri: `${window.location.href}/after`,
    clearHashAfterLogin: environment.authentication.clearHashAfterLogin,
    disableAtHashCheck: environment.authentication.disableAtHashCheck,
    requestAccessToken: environment.authentication.requestAccessToken,
    scope: environment.authentication.scope,
    showDebugInformation: environment.authentication.showDebugInformation,
    skipIssuerCheck: environment.authentication.skipIssuerCheck,
    strictDiscoveryDocumentValidation: environment.authentication.strictDiscoveryDocumentValidation,
    timeoutFactor: environment.authentication.timeoutFactor,
    postLogoutRedirectUri: `${window.location.origin}/logout`,
    responseType: environment.authentication.responseType
};

// export const authConfig: AuthConfig = {
//     clientId: environment.authentication.clientId,
//     redirectUri: window.location.origin,
//     responseType: environment.authentication.responseType,
//     requestAccessToken: environment.authentication.requestAccessToken,
//     scope: environment.authentication.scope,
//     dummyClientSecret: environment.authentication.dummyClientSecret || null,
//     issuer: 'https://accountsstage.b2clogin.cn/f0af5415-725c-4a40-b2e1-cf5ad573c4ee/v2.0/',
//     skipIssuerCheck: environment.authentication.skipIssuerCheck,
//     strictDiscoveryDocumentValidation: environment.authentication.strictDiscoveryDocumentValidation,
//     showDebugInformation: true,
//     waitForTokenInMsec: 100000,
// };

export const discoverDocumentOverride = environment.authentication.overrideDiscoveryDocumentUri;

export const discoverDocumentOverrideSignUp = environment.authentication.overrideDiscoveryDocumentUriSignUp;
