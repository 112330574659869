/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */

export class SinglePermission {

    public key: string;

    public description: string;

    public component: string;
}

export class RoleAssignment {

    public id: string;

    public roleId: string;

    public description: string;

    public isConfigurable: boolean;

    public permissions: SinglePermission[];
}
