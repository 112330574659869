import { Component } from '@angular/core';
import { PreferencesService } from '../../services/preferences.service';


@Component({
   selector: "vcld-adm-unsaved-settings-change",
   templateUrl: "./unsaved-settings-changes.dialog.component.html",
   styleUrls: ["./unsaved-settings-changes.dialog.component.scss"]
})
export class UnsavedSettingsChangeDialogComponent {
   constructor(
      private pref: PreferencesService,
   ) { }
   public saveAll(): void {
      this.pref.saveAllselected$.next(true);
   }

}