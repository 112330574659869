import { AfterViewInit, Directive, ElementRef, HostListener,Renderer2 } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Directive({
  selector: '[overflowingTooltip]',
  providers: [MatTooltip],
  host: {
    'class': 'overflowingTooltip'
  }
})

export class overflowingTooltipDirective {

  constructor(private elementRef: ElementRef,private tooltip: MatTooltip, private renderer: Renderer2) {}

  isTouched:boolean=false

  @HostListener('mouseenter')
  onMouseEnter(): void {
    this.addTooltip();
  }

  @HostListener('touchstart')
  onTouchStart(): void {
   this.addTooltip();
  }

  @HostListener('mouseleave')
   mouseleave() {
    this.tooltip.hide();
  }

  @HostListener("document:mousewheel", ["$event"])
    onMouseWheel() {
     this.tooltip.hide();
    }

  addTooltip(): void {
    requestAnimationFrame(() => {
      const element = this.elementRef.nativeElement;
      if (element.offsetWidth < element.scrollWidth) {
        this.tooltip.message = element.tagName=="INPUT"? element.value: element.textContent;
        this.tooltip.show();
        this.isTouched=true;
      }
      else if (this.isTouched) this.removeElement();
    });
  }
  
  removeElement() {
    const elements = document.getElementsByClassName('mdc-tooltip');
    if (elements?.length > 0) {
        this.renderer.removeChild(this.elementRef.nativeElement, elements[0])
    }
    this.isTouched=false;
}
}