import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'vcld-adm-nav-tile',
  templateUrl: './navigation-tile.component.html',
  styleUrls: ['./navigation-tile.component.scss']
})
export class NavigationTileComponent {

  @Input()
  public icon: string = 'adb';
 
  @Input()
  public wrapperIcon: string = null;

  @Input()
  public styleForNoWraper: string = null;

  @Input()
  public additionalIcon: string;

  @Input()
  public title: string = 'Default Navigation Tile Title';

  @Input()
  public link: string = '/home';

  @Input()
  public source: string = null;

  @Input()
  public disabled: boolean = false;

  @Input()
  public disableTooltip: boolean = true;

  public get twoIcons (): boolean {
    return this.additionalIcon!==undefined;
  }

  constructor(private router: Router, private route: ActivatedRoute) { }

  public navigate() {
    if (!this.disabled) {
      this.router.navigate([this.link], { relativeTo: this.route, queryParams: { source: this.source } });
    }
  }
}
