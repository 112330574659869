/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable max-len */
import { Component, Input, AfterViewInit, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, distinctUntilChanged, startWith } from 'rxjs/operators';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
    selector: 'vcld-image',
    template: `
    <img *ngIf="dataUrl$ | async as image else placeholder"
      draggable="false"
      [src]="image"
      [style.width]="width"
      [style.height]="height"
      [style.maxHeight]="maxHeight"
      style="background-position: center;"
    />

    <ng-template #placeholder>
    <img
      class="placeholder-image"
      src="assets/placeholder_thumbnail.png"
      [style.width]="width"
      [style.height]="height"
      [style.maxHeight]="maxHeight"
      style="background-posiiton: center;"
      draggable="false"/>
    <ng-template>
    `
})
export class SecureImageComponent implements OnInit {


    @Input()
    private imgSrc: Observable<any>;

    @Input()
    width = '300px';

    @Input()
    height = 'auto';

    @Input()
    maxHeight = '500px';

    dataUrl$: Observable<SafeUrl>;

    constructor(private domSanitizer: DomSanitizer) { }

    public ngOnInit() {
        this.dataUrl$ = this.loadImage();
    }

    private loadImage() {
        return this.imgSrc
            .pipe(
              distinctUntilChanged(),
              map(e => {
                if (e) {
                  return this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(e));
                }
                return null;
              }),
              startWith(null)
            );
    }
}
