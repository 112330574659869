import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { map } from 'rxjs/operators';
import { ZeissIdBase, ZeissIdToken } from 'visauto-auth';
import { CampaignService } from '../../../app-dmt/services/campaign.service';
import { FeatureFlagsService } from '../../../handler/featureFlag.service';
import { SecurityService } from '../../services/security.service';
import { SnackbarComponent } from '../../components/snackbar/snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'vis-adm-overview-page',
    templateUrl: './admin-overview.page.component.html',
    styleUrls: ['./admin-overview.page.component.scss']
})
export class AdminOverviewPageComponent implements OnInit {

    private hasMarketing: boolean = false;

    constructor(
        private oAuth: OAuthService, 
        private router: Router, 
        private campaign: CampaignService,
        private security: SecurityService,
        private features: FeatureFlagsService,
        private snack: MatSnackBar,
        ) { }

    public settings$ = this.campaign.settings$.pipe();

    async ngOnInit(): Promise<void> {
        this.hasMarketing = await this.security.canSeeAsync('admin_view_marketing', false) && this.features.isFeatureFlagEnabled('DMTEnabled');

        if(this.hasMarketing) {
            this.campaign.fetchDMTSettings();
        }

        if (this.features.isFeatureFlagEnabled('AnnouncementBannerEnabled') && this.security.showAnnouncementBanner){
            this.snack.openFromComponent(SnackbarComponent, {
                data: {
                    icon: 'info',
                    heading: "errors.information",
                    message: "pages.adm-overview.announcement_banner",
                    btnIcon: 'close',
                    support: false
                },
                panelClass: 'error-panel',
                verticalPosition: 'top',
                horizontalPosition: 'center',
            });
            this.security.showAnnouncementBanner = false;
        }
    }

    public get userName() {
        const claims = this.oAuth.getIdentityClaims() as ZeissIdToken;
        if (claims == null) {
            return '';
        }
        const base = JSON.parse(claims.ZeissIdBase) as ZeissIdBase;

        return `${base.firstName} ${base.lastName}`;
    }
}
