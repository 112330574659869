import { Pipe, PipeTransform } from '@angular/core';

const availableStatus = ['NOT STARTED', 'PENDING', 'APPROVED', 'REJECTED'];
const icons = ['not_started', 'pending', 'done_all', 'not_interested']

@Pipe({ name: 'jobStatus' })
export class JobStatusPipe implements PipeTransform {
    transform(value: number): string {
        return value > -1 && value < icons.length ? icons[value] : 'error';
        return value > -1 && value < availableStatus.length ? availableStatus[value] : 'INVALID';
    }
}
